import { showToast } from '@/lib/toast';

// Utility functions for localStorage operations
export const getFromLocalStorage = (key: string): string | undefined => {
  try {
    return localStorage.getItem(key) || undefined;
  } catch (error) {
    return undefined;
  }
};

export const setInLocalStorage = (key: string, value: string | undefined) => {
  try {
    if (value) {
      localStorage.setItem(key, value);
    } else {
      localStorage.removeItem(key);
    }
  } catch (error) {
    showToast(`Failed to save ${key}`, { type: 'error' });
  }
};
