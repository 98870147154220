import Image from '@/components/atoms/Image';
import Button, { ButtonVariants } from '@/components/atoms/legacy/Button';
import Heading, { HeadingLevels } from '@/components/atoms/legacy/Heading';
import Icon, { IconIds } from '@/components/atoms/legacy/Icon';
import ClampedText from '@/components/molecules/ClampedText';
import { EVComparisonChainModel } from '@/components/organisms/EVComparisonChainModel/EVComparisonChainModel';
import {
  COMPARE,
  FAVORITE,
  POPULAR_EVS_COMPARISON,
  SIDE_BY_SIDE,
  START_COMPARISON,
} from '@/lib/constants';
import { VehiclePreview } from '@/lib/schema/inventory/types';
import { EVDetailProps } from '@/types/compare-ev';
import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';

export interface SelectedModelsProps extends VehiclePreview {
  imageURL: string;
}

interface EVComparisonLandingTemplateProps {
  makes: string[];
  selectedModelsCompare: SelectedModelsProps[][];
}

export const EVComparisonLandingTemplate: React.FC<
  EVComparisonLandingTemplateProps
> = ({ makes, selectedModelsCompare }) => {
  const [modalStep, setModalStep] = useState(0);
  const router = useRouter();

  function goToNextModal(step: number) {
    setModalStep(step + 1);
  }

  const handlePopularEVClick = async (pairModels: SelectedModelsProps[]) => {
    const { imageURL: imageUrl1, ...rest1 } = pairModels[0];
    const { imageURL: imageUrl2, ...rest2 } = pairModels[1];
    sessionStorage.setItem('firstEVImageUrl', imageUrl1);
    sessionStorage.setItem('secondEVImageUrl', imageUrl2);
    router.push({
      pathname: '/compare/details',
      query: {
        firstEV: JSON.stringify(rest1),
        secondEV: JSON.stringify(rest2),
      },
    });
  };

  const onSelectTrims = useCallback(
    async (body: {
      selectedDefaultDetails: EVDetailProps;
      firstEV: EVDetailProps;
      secondEV: EVDetailProps;
    }) => {
      const { selectedDefaultDetails, firstEV, secondEV } = body;
      const response = await fetch(
        `/api/compare/getVehicleStylesData/${selectedDefaultDetails.year}/${
          selectedDefaultDetails.make
        }/${selectedDefaultDetails.model}?trim=${encodeURIComponent(
          selectedDefaultDetails.trim
        )}`
      );

      if (response.ok) {
        const { imageUrl: imageUrl1, ...rest1 } = firstEV;
        const { imageUrl: imageUrl2, ...rest2 } = secondEV;
        sessionStorage.setItem('firstEVImageUrl', imageUrl1);
        sessionStorage.setItem('secondEVImageUrl', imageUrl2);
        await router.push({
          pathname: '/compare/details',
          query: {
            firstEV: JSON.stringify(rest1),
            secondEV: JSON.stringify(rest2),
          },
        });
      }
      return response.ok;
    },
    [router]
  );

  return (
    <>
      <section className="mb-[32px] flex h-auto w-full flex-col items-center justify-between bg-background-light">
        <Heading
          className="w-full px-[50px] pt-[48px] text-center text-[clamp(1.5rem,2.5vw,2.2rem)]"
          level={HeadingLevels.H4}
        >
          <span>{COMPARE}</span>
          <span className="inline text-brandElectric">{FAVORITE}</span>
          <br />
          <span>{SIDE_BY_SIDE}</span>
        </Heading>
        <div className="flex h-full w-full justify-center px-[20px] py-[16px] m:px-[120px] m:py-[32px] l:px-[350px]">
          <Image
            className="!relative !h-[150px] rounded-[4px] m:!h-[250px] l:!h-[300px]"
            src="/images/EV_comparison_landing_image.png"
            alt="EV_comparison_landing"
            fill
            style={{ objectFit: 'cover' }}
          />
        </div>
        <div className="mb-[32px]">
          <Button
            variant={ButtonVariants.newPrimary}
            onClick={() => setModalStep(1)}
            className="!py-[8px] !text-[clamp(1rem,1vw,1.5rem)] l:!py-[12px]"
          >
            {START_COMPARISON}
          </Button>
        </div>
      </section>
      <section className="mb-[20px] flex w-full justify-around l:mb-[40px]">
        <section className="h-full w-full bg-background-light m:w-[50%]">
          <Heading
            className="mb-[20px] w-full px-[20px] pt-[30px] text-[clamp(1rem,1.3vw,1.5rem)] l:mb-[40px] l:px-[40px]"
            level={HeadingLevels.H4}
          >
            <span>{POPULAR_EVS_COMPARISON}</span>
          </Heading>
          <section>
            <section className="flex flex-col px-[20px] l:px-[40px]">
              {selectedModelsCompare.map((pairModels, index: number) => (
                <div
                  className="mb-[16px] flex w-full items-center justify-center space-x-8 rounded-[4px] bg-white p-4 hover:cursor-pointer hover:shadow-md hover:ring-2 hover:ring-brandSecondary hover:ring-opacity-50 m:mb-[24px]"
                  key={index}
                  onClick={() => handlePopularEVClick(pairModels)}
                >
                  <div className="align-center flex flex-col justify-center text-center">
                    <div className="flex min-h-[72px] items-center">
                      <Image
                        src={pairModels[0].imageURL}
                        alt="compare evs"
                        className="!relative mx-auto -scale-x-100 transform object-contain"
                        fill
                      />
                    </div>
                    <ClampedText className="whitespace-nowrap text-[12px] text-label l:text-[16px]">
                      {pairModels[0].year} {pairModels[0].make}
                    </ClampedText>
                    <p className="text-[12px] l:text-[16px]">
                      {pairModels[0].model}
                    </p>
                    <ClampedText className="text-[12px] text-label l:text-[14px]">
                      {pairModels[0].trim}
                    </ClampedText>
                  </div>
                  <div className="h-[36px] w-[36px]">
                    <Icon iconId={IconIds.Verse} />
                  </div>
                  <div className="align-center flex flex-col justify-center text-center">
                    <div className="flex min-h-[72px] items-center">
                      <Image
                        src={pairModels[1].imageURL}
                        alt="compare evs"
                        className="!relative mx-auto object-contain"
                        fill
                      />
                    </div>
                    <ClampedText className="whitespace-nowrap text-[12px] text-label l:text-[14px]">
                      {pairModels[1].year} {pairModels[1].make}
                    </ClampedText>
                    <p className="text-[12px] l:text-[14px]">
                      {pairModels[1].model}
                    </p>
                    <ClampedText className="text-[12px] text-label l:text-[14px]">
                      {pairModels[1].trim}
                    </ClampedText>
                  </div>
                </div>
              ))}
            </section>
          </section>
        </section>
      </section>
      <EVComparisonChainModel
        modalStep={modalStep}
        goToNextModal={goToNextModal}
        makes={makes}
        onSubmit={onSelectTrims}
      />
    </>
  );
};
