import { getPublicIpAddress } from '@/lib/getPublicIpAddress';
import { getFromLocalStorage, setInLocalStorage } from '@/lib/localStorage';
import { useCallback, useEffect, useState } from 'react';

export interface Address {
  zip: string;
  city: string;
  state: string;
  country: string;
}

/**
 * Gets the user's Address (based on ip location)
 */
export const useLocationZip = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [locationAddress, setLocationAddress] = useState<Address | undefined>(
    undefined
  );

  const fetchLocationAddress = useCallback(async () => {
    setLoading(true);
    const ip = await getPublicIpAddress();
    try {
      const res = await fetch(`https://ipapi.co/${ip}/json/`);
      if (!res.ok) {
        throw new Error('failed to fetch IP geodata');
      }
      const data = await res.json();
      const newAddress: Address = {
        zip: data.postal,
        city: data.city,
        state: data.region_code,
        country: data.country_code,
      };
      return newAddress;
    } catch (e) {
      setError(true);
      return undefined;
    } finally {
      setLoading(false);
    }
  }, []);

  const getLocationAddress = useCallback(async () => {
    const cachedAddress = getFromLocalStorage('userAddress');
    if (cachedAddress) {
      setLocationAddress(JSON.parse(cachedAddress) as Address);
      setLoading(false);
    } else {
      const address = await fetchLocationAddress();
      if (address) {
        setInLocalStorage('userAddress', JSON.stringify(address));
        setLocationAddress(address);
      }
    }
  }, [fetchLocationAddress]);

  useEffect(() => {
    getLocationAddress();
  }, [getLocationAddress]);

  return { locationAddress, loading, error };
};
